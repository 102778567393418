import {useEffect, useState} from "react";
import {supabase} from "./supabase/db";

export const useIsAuthed = () => {
    const [isAuted, setIsAuthed] = useState(false)
    useEffect(() => {
        supabase.auth.getSession().then(res => {
            setIsAuthed(!!res.data.session)
        })
    })
    return isAuted
}

export const useLoggedInEmail = () => {
    const [email, setEmail] = useState<string | null>(null)
    useEffect(() => {
        supabase.auth.getSession().then(res => {
            res.data.session?.user && res.data.session.user.email ? setEmail(res.data.session.user.email) : setEmail(null)
        })
    })

    return email
}

export const useLatestPost = () => {
    const [post, setPost] = useState<any>(null)
    useEffect(() => {
        supabase.from("news").select().order("created_at", {ascending: false}).limit(1).single().then(res => setPost(res.data))
    }, [])
    return post
}