import React from "react";
import {Container} from "react-bootstrap";

const WelcomeComponent = () => {
    return (
        <React.Fragment>

            <Container fluid style={{textAlign: "center", color:"grey", marginTop:20}}>
                    <h1>Välkommen till LoFast</h1>
                    <p>Om du inte hittar den lokal du
                        söker, tveka inte att
                        kontakta oss via mail eller telefon. </p>
                    <ul>
                        <li> Email: <a href="mailto:info@lofast.se"> info@lofast.se</a></li>
                        <li> Telefon: <a href="tel:031101950"> 031-101950</a></li>
                    </ul>
            </Container>
        </React.Fragment>
    )
}
export default WelcomeComponent
