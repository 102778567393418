import React, { useState } from 'react'
import { Password } from "primereact/password";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { supabase} from "../../supabase/db";
import {useNavigate} from "react-router-dom";


export const Login = ()  => {
	
	const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("")

	const navigate = useNavigate()
	
    const onLogin = async (email: string, password: string) => {

		const { error } = await supabase.auth.signInWithPassword({
			email: email,
			password: password
		})

		if(!error) {
			navigate("/", {relative: "route"})
		}else {
			window.alert("Fel email och lösenord")
		}
		console.log("ERR", error)
    }
    
    return (
		<div>
			<InputText 
			value={email}
			onChange={(e) => setEmail(e.target.value)}
			placeholder="Email"
			/>
			
			<Password
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				feedback={false}
				toggleMask
				placeholder="Lösenord"
			/>
			<Button
				onClick={() =>
					onLogin(email, password)
				}>
				Logga in
			</Button>
		</div>
  );
}
