import React, {useEffect, useState} from "react";
import {Premises} from "../../data/Premises";
import {DataTable} from "primereact/datatable";
import {Column, ColumnFilterElementTemplateOptions} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Container} from "react-bootstrap";
import {FilterMatchMode} from "primereact/api";
import {supabase} from "../../supabase/db";
import {isMobile} from "react-device-detect";
import {useIsAuthed} from "../../hooks";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {Link} from "react-router-dom";

export const PremisesList: React.FC = () => {
    
    const [premisesList, setPremisesList] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState<number>(1);
    
    const isAuthed = useIsAuthed()
    
    const premisesTypeValue = (premises: Premises): String => {
        return premises.premisesType;
    };
    
    const premisesImage = (premises: Premises): any => {
        return (
            <React.Fragment>
                <img
                    src={premises.images ? premises.images[0] : ""}
                    alt="lokalbild"
                    width={isMobile ? 130 : 250}
                    height={isMobile ? 90 : 175}
                />
            </React.Fragment>
        );
    };
    
    const footer = `Totalt antal lokaler ${
        premisesList ? premisesList.length : 0
    } lokaler.`;
    
    const [filters, setFilters] = useState({
        global: {value: null, matchMode: FilterMatchMode.CONTAINS},
        adress: {value: null, matchMode: FilterMatchMode.CONTAINS},
        city: {value: null, matchMode: FilterMatchMode.CONTAINS},
        propertyOwner: {value: null, matchMode: FilterMatchMode.CONTAINS},
        premisesType: {value: null, matchMode: FilterMatchMode.CONTAINS},
    });
    
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    
    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = {...filters};
        _filters["global"].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    
    const renderHeader = () => {
        return (
            <div>
				<span className="p-input-icon-left">
					<i className="pi pi-search"/>
					<InputText
                        style={{width: isMobile ? 350 : 600}}
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Sök på stad, adress eller lokaltyp"
                    />
				</span>
            </div>
        );
    };
    
    const adressBody = (premises: Premises) => {
        return <h5>{premises.adress}</h5>
    }
    
    const premisesTypeFilter = (options: ColumnFilterElementTemplateOptions) => {
        return <Dropdown value={options.value} options={["Kontor", "Lager", "Kombi", "Butik"]}
                         onChange={(e) => options.filterApplyCallback(e.value)}
                         itemTemplate={premisesTypeFilterTemplate} placeholder="Välj lokaltyp"
                         className="p-column-filter" showClear/>;
    }
    const premisesTypeFilterTemplate = (option: String) => {
        return <span>{option}</span>;
    }
    
    const goToBody = (premises: Premises) => {
        return (
            <Link to={`/premises/${premises.imageId}`}> <Button label="Visa"/></Link>
        )
    }
    
    const onPageChange = (event: any) => {
        setCurrentPage(event.page + 1);
        localStorage.setItem("premisesListPage", JSON.stringify(event.page + 1));
    };
    
    useEffect(() => {
        const storedPage: string | null = localStorage.getItem("premisesListPage");
        if (storedPage && Number(storedPage)) {
            setCurrentPage(Number(storedPage));
        }
        const fetchPremises = async () => {
            const {data} = await supabase
                .from("Premises")
                .select()
                .order("created_at", {ascending: false});
            if (data) {
                setPremisesList(data)
            }
        }
        fetchPremises()
    }, [])
    
    return (
        <Container fluid style={{height: "85vh", width: "90vw"}}>
            <DataTable
                value={premisesList ? premisesList : []}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} av {totalPages}"
                onPage={onPageChange}
                first={(currentPage - 1) * 10}
                rows={10}
                rowsPerPageOptions={[10, 20, 50]}
                footer={footer}
                header={renderHeader()}
                filters={filters}
                filterDisplay="row"
                globalFilterFields={["adress", "city", "premisesType", "propertyOwner"]}
                emptyMessage="Inga lokaler hittade"
                responsiveLayout="stack"
                breakpoint="760px"
            >
                <Column
                    columnKey={"adress"}
                    field="adress"
                    header="Adress"
                    filter={!isMobile}
                    filterPlaceholder="Adress"
                    showFilterMenu={false}
                    body={adressBody}
                    sortable
                ></Column>
                <Column
                    field="images"
                    header="Bild"
                    body={(premises: Premises) =>
                        premisesImage(premises)
                    }></Column>
                <Column
                    columnKey="city"
                    field="city"
                    header="Stad"
                    filterField="city"
                    filter={!isMobile}
                    showFilterMenu={false}
                    filterPlaceholder="Stad/Område"
                    sortable></Column>
                
                <Column
                    field="premisesType"
                    header="Lokaltyp"
                    body={(premises: Premises) => premisesTypeValue(premises)}
                    filter
                    showFilterMenu={false}
                    filterElement={premisesTypeFilter}
                ></Column>
                
                
                <Column field="size" header="Kvadratmeter" sortable> </Column>
                
                
                {isAuthed ? (
                    <Column
                        field="propertyOwner"
                        filterField="propertyOwner"
                        filter={!isMobile}
                        showFilterMenu={false}
                        filterPlaceholder="Fastighetsägare"
                        header="Fastighetsägare"
                        sortable></Column>) : <React.Fragment/>}
                
                <Column body={goToBody}></Column>
            </DataTable>
        </Container>
    );
};
