import React, {useRef, useState} from "react";
import {supabase} from "../supabase/db";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import Modal from 'react-bootstrap/Modal';
import {Container, Stack} from "react-bootstrap";
import {ErrorText} from "./User/ErrorText";
import {Button} from "primereact/button";
import * as Yup from "yup";
import {
    FileUpload,
    FileUploadHandlerParam,
    FileUploadHeaderTemplateOptions,
} from "primereact/fileupload";
import {CardContent} from "../components/PremisesContent/CardContent";
import {Premises} from "../data/Premises";
import {uuid} from "@supabase/supabase-js/dist/main/lib/helpers";
import {useNavigate} from "react-router-dom";
import {handleImageUploadWithRenames} from "../supabase/supabaseImageUploader";
import {ProgressSpinner} from "primereact/progressspinner";

export const Upload = () => {

    const [showPreview, setShowPreview] = useState(false)

    const [mustHaveImagesMessage, setMustHaveImagesMessage] = useState<string>("")

    const [loading, setLoading] = useState<boolean>(false)

    const [premisesBody, setPremisesBody] = useState<Premises>({} as Premises)

    const uuidState = useRef<String>(uuid())

    const initialValues = {
        adress: "",
        city: "",
        premisesType: "Kontor",
        size: "",
        description: "",
        propertyOwner: "",
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        images: [],
        note: ""
    };

    const myUploader = (e: FileUploadHandlerParam) => {
        if (formikRef.current.values.adress.length > 0) {
            let adress = formikRef.current.values.adress
            adress = adress.replace(/å/g, 'a')
                .replace(/Å/g, 'A')
                .replace(/ä/g, 'a')
                .replace(/Ä/g, 'A')
                .replace(/ö/g, 'o')
                .replace(/Ö/g, 'O')
                .replaceAll('/', '-')
            console.log("ADRESS", adress)

            let fileArr: File[] = []
            e.files.forEach((file: File, index: number) => {
                let blob = file.slice(0, file.size, file.type)
                let fileTypeString = file.type.split("/")[1]
                fileArr[index] = new File([blob], `${uuidState.current}_${adress}_${index}.${fileTypeString}`, {type: blob.type})
                console.log("FILE IN LOOP", file)
            })

            console.log(fileArr)

            formikRef.current.values.images = fileArr
        } else {
            removedImage()
            window.alert("Måste välja adress innan du laddar upp bilder")
        }
    }

    const removedImage = () => {
        fileUploadRef.current.clear()
        formikRef.current.values.images = null
    }

    const onSubmitPremises = (values: FormikValues) => {
        setPremisesBody(values as Premises)
        setShowPreview(true)
    }

    const handleOnsubmit = async (premisesBody: Premises) => {
        setLoading(true)
        let imagePathArray: string[] = []
        await handleImageUploadWithRenames(premisesBody, imagePathArray, formikRef)

        await supabase.from('Premises').insert([
            {
                adress: premisesBody.adress,
                city: premisesBody.city,
                premisesType: premisesBody.premisesType,
                size: premisesBody.size,
                description: premisesBody.description,
                propertyOwner: premisesBody.propertyOwner,
                contactName: premisesBody.contactName,
                contactPhone: premisesBody.contactPhone,
                contactEmail: premisesBody.contactEmail,
                images: imagePathArray.length > 0 ? imagePathArray : [],
                imageId: uuidState.current,
                note: premisesBody.note
            },
        ]).then((res) => console.log("response insert", res))

        return new Promise<PremisesResponse>((resolve, reject) => {
            resolve({id: uuidState.current, success: true})
            reject(false)
        })
    }

    const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
        const {className, chooseButton, uploadButton, cancelButton} = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
            </div>
        );
    }

    const formikRef = useRef<any>(null)
    const fileUploadRef = useRef<any>(null);
    const navigate = useNavigate()

    return (
        <div>
            <Container>
                <Formik
                    innerRef={formikRef}
                    initialValues={initialValues}
                    validationSchema={premisesSchema}
                    onSubmit={(values) => {
                        console.log(formikRef.current.values.images)
                        if (formikRef.current.values.images.length > 0) {
                            onSubmitPremises(values)
                            setMustHaveImagesMessage("")
                        } else {
                            setMustHaveImagesMessage("Du måste välja bilder till lokalen")
                        }

                    }}
                >
                    <Form>
                        <Stack gap={3}>
                            {/* ADRESS */}
                            <label className="block" htmlFor="adress">
                                Adress
                            </label>
                            <Field
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                id="adress"
                                placeholder="Adress"
                                name="adress"
                            />
                            <ErrorMessage name="adress" component={ErrorText}/>

                            {/* CITY */}
                            <label className="block" htmlFor="city">
                                Stad
                            </label>
                            <Field
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                id="city"
                                placeholder="Stad"
                                name="city"
                            />
                            <ErrorMessage name="city" component={ErrorText}/>

                            {/* LOKALTYP */}
                            <label htmlFor="Email" className="block">
                                Lokaltyp
                            </label>
                            <Field
                                as="select"
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                id="premisesType"
                                placeholder="Lokaltyp"
                                name="premisesType"
                            >
                                <option value="Kontor"> Kontor</option>
                                <option value="Kombi"> Kombi</option>
                                <option value="Lager"> Lager</option>
                                <option value="Butik"> Butik</option>
                            </Field>
                            <ErrorMessage name="premisesType" component={ErrorText}/>

                            {/* STORLEK */}
                            <label htmlFor="Phone" className="block">
                                Storlek
                            </label>
                            <Field
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                id="size"
                                placeholder="Storlek"
                                name="size"
                            />
                            <ErrorMessage name="size" component={ErrorText}/>

                            {/* description */}
                            <div>
                                <label htmlFor="description" className="block">
                                    Beskrivning
                                </label>
                                <Field
                                    className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                    as="textarea"
                                    id="description"
                                    placeholder="Beskrivning"
                                    name="description"
                                    style={{height: 200, width: "100%"}}
                                />
                                <ErrorMessage name="description" component={ErrorText}/>
                            </div>
                            {/* PropertyOwner */}
                            <label htmlFor="Phone" className="block">
                                Fastighetsägare
                            </label>
                            <Field
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                id="propertyOwner"
                                placeholder="Fastighetsägare"
                                name="propertyOwner"
                            />
                            <ErrorMessage name="propertyOwner" component={ErrorText}/>

                            {/* contactName */}
                            <label htmlFor="Phone" className="block">
                                Kontaktperson
                                Namn
                            </label>
                            <Field
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                id="contactName"
                                placeholder="Namn"
                                name="contactName"
                            />
                            <ErrorMessage name="contactName" component={ErrorText}/>

                            {/* contactEmail */}
                            <label htmlFor="Phone" className="block">
                                Email
                            </label>
                            <Field
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                id="contactEmail"
                                placeholder="Email"
                                name="contactEmail"
                            />
                            <ErrorMessage name="contactEmail" component={ErrorText}/>

                            {/* contactPhone */}
                            <label htmlFor="Phone" className="block">
                                Telefonnummer
                            </label>
                            <Field
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                id="contactPhone"
                                placeholder="Telefonnummer"
                                name="contactPhone"
                            />
                            <ErrorMessage name="contactPhone" component={ErrorText}/>
    
                            {/* note */}
                            <label htmlFor="note" className="block">
                                Anteckning
                            </label>
                            <Field
                                className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                as="textarea"
                                id="note"
                                placeholder="Anteckning"
                                name="note"
                            />

                            <FileUpload ref={fileUploadRef} id="images" multiple accept="image/*"
                                        maxFileSize={400000} customUpload={true}
                                        uploadHandler={myUploader}
                                        emptyTemplate={<p className="m-0">Drag and drop files to here to
                                            upload.</p>}
                                        previewWidth={250}
                                        auto
                                        onRemove={removedImage}
                                        uploadLabel={"Ladda upp"}
                                        cancelLabel={"Avbryt"}
                                        chooseLabel={"Välj bilder att ladda upp"}
                                        headerTemplate={headerTemplate}/>
                            <Button type="submit" style={{margin: 10}} label="Lägg till lokal"/>
                            <p style={{color: "red", fontSize: "25"}}> {mustHaveImagesMessage}</p>
    
                           
                        </Stack>
                    </Form>
                </Formik>


                {/* PUBLISH THE PREMISES*/}
                <Modal show={showPreview && premisesBody != null} onHide={() => setShowPreview(false)}
                       size={"xl"}>
                    <Modal.Header>
                        <Modal.Title>Så här ser den ut <p>OBS: Bilder syns ej, se till att alla bildnamn finns
                            med</p></Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <CardContent adress={premisesBody.adress}
                                     city={premisesBody.city}
                                     premisesType={premisesBody.premisesType} size={premisesBody.size}
                                     description={premisesBody.description ? premisesBody.description : ""}
                                     contactPhone={premisesBody.contactPhone ? premisesBody.contactPhone : ""}
                                     contactEmail={premisesBody.contactEmail ? premisesBody.contactEmail : ""}
                                     contactName={premisesBody.contactName ? premisesBody.contactName : ""}
                                     images={premisesBody.images ? premisesBody.images : []}
                                     imageId={uuidState.current}
                                     note={premisesBody.note ? premisesBody.note : ""}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="p-button-secondary" onClick={() => setShowPreview(false)}>
                            Avbryt
                        </Button>
                        <Button onClick={async () => {
                            await handleOnsubmit(premisesBody).then((res: PremisesResponse) => {
                                if (res.success) {
                                    setLoading(false)
                                    navigate(`/premises/${res.id}`, {relative: "route"})
                                } else {
                                    setLoading(false)
                                    window.alert("Något gick fel")
                                }

                            })
                        }} label={"Ladda upp lokal"}>
                            {loading ? (<ProgressSpinner/>) : <React.Fragment/>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </div>

    )
}

export const premisesSchema = Yup.object().shape({
    adress: Yup.string().required("Address required").min(2).max(40),
    city: Yup.string().required("City required"),
    premisesType: Yup.string().required("Välj lokaltyp").oneOf(["Lager", "Kontor", "Kombi", "Butik"]),
    size: Yup.number().required("Size required and must be number"),
    description: Yup.string().required("Beskrivning krävs"),
    propertyOwner: Yup.string().required("PropertyOwner krävs"),
    contactName: Yup.string().required("ContactName krävs"),
    contactEmail: Yup.string().required("ContactEmail krävs"),
    contactPhone: Yup.string().required("ContactPhone krävs"),
    images: Yup.array()
});

export type PremisesResponse = {
    id: String | undefined;
    success: Boolean;
}
