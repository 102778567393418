import React, {ReactElement} from "react";
import {Container, Stack, Row, Col} from "react-bootstrap";
import {Premises} from "../../data/Premises";
import {Carousel} from "primereact/carousel";
import {Card} from "primereact/card";
import {isMobile} from 'react-device-detect';
import {useIsAuthed} from "../../hooks";
import {Divider} from "primereact/divider";

export const CardContent: React.FC<Premises> = ({...premises}: Premises) => {
    
    const isAuthed = useIsAuthed()
    
    const carouselItemTemplate = (image: string) => {
        return <Container style={{marginTop: 10}}>
            <img src={image} alt={"Bild hittades ej"} width={isMobile ? 120 : 900} height={isMobile ? 95 : 580}></img>
        </Container>
    };
    
    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '768px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];
    
    
    const ContactPerson = (props: { premises: Premises }): ReactElement => {
        let name = props.premises.contactName ? props.premises.contactName : "Inget namn";
        let phone = props.premises.contactPhone ? props.premises.contactPhone : "inget telefonnummer"
        let email = props.premises.contactEmail ? props.premises.contactEmail : "Inget mailadress"
        
        return (
            <Card>
                <p>
                    Kontaktperson:
                </p>
                <p>
                    <b>{name}</b>
                </p>
                <p>
                    <b>{phone}</b>
                </p>
                <p>
                    <b>{email}</b>
                </p>
            </Card>
        );
    }
    
    
    return (
        <Container>
            <Row>
                <Col md={4}>
                    <Stack gap={3}>
                        <p>
                            Adress: <b>{premises.adress}</b>
                        </p>
                        <p>
                            Stad: <b>{premises.city}</b>
                        </p>
                        <p>
                            Lokaltyp: <b>{premises.premisesType}</b>
                        </p>
                        <p>
                            Storlek: <b>{`${premises.size} kvm.`}</b>
                        </p>
                    </Stack>
                </Col>
                <Col md={6}>
                    <Card>
                        <p>
                            <b> Info om lokalen:</b>
                        </p>
                        <p>{premises.description}</p>
                    </Card>
                </Col>
            </Row>
            
            <Carousel
                circular={true}
                responsiveOptions={responsiveOptions}
                value={premises.images ? premises.images : []}
                itemTemplate={carouselItemTemplate}
            />
            
            {isAuthed && premises ? (
                <React.Fragment>
                    <ContactPerson premises={premises}/>
                    <Divider/>
                    <Card>
                        <p> <b>Anteckning:</b> {premises.note}</p>
                    </Card>
                </React.Fragment>
            
            ) : (
                <Card>
                    <p>
                        Kontaktperson:
                    </p>
                    <p>
                        <b>{"Per Hertzberg"}</b>
                    </p>
                    <p>
                        <b><a href="tel:0708972343">{"0708-972343"}</a></b>
                    </p>
                    <p>
                        <b><a href="mailto:per@lofast.se"> per@lofast.se</a></b>
                    </p>
                </Card>
            )}
        </Container>
    );
};
