import React, {useEffect, useRef, useState} from "react";
import {Container, Stack} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {supabase} from "../supabase/db";
import {PREMISES_TABLE} from "../constants/Urls";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import {ErrorText} from "./User/ErrorText";
import {Button} from "primereact/button";
import {PremisesResponse, premisesSchema} from "./Upload";
import {Premises, PremisesType} from "../data/Premises";
import Modal from "react-bootstrap/Modal";
import {CardContent} from "../components/PremisesContent/CardContent";

export const EditPremises = () => {

    const premisesId = useParams()
    const [originalPremises, setOriginalPremises] = useState<any>(null)
    const [isAuthed, setIsAuthed] = useState<boolean>(true)
    const [premisesBody, setPremisesBody] = useState<Premises>({} as Premises)
    const [showPreview, setShowPreview] = useState(false)
    const [initialValues, setInitialValues] = useState<any>(null)

    const formikRef = useRef<any>(null)
    const navigate = useNavigate()

    useEffect(() => {
        supabase.auth.getSession().then(res => setIsAuthed(!!res.data.session))
        const fetchPremisesById = async () => {
            const {data} = await supabase
                .from(PREMISES_TABLE)
                .select()
                .eq("imageId", premisesId.id)
                .single()
            setOriginalPremises(data)


            let dataAsPremises : Premises = data as Premises

            return new Promise<Premises>((resolve, reject) => {
                resolve(dataAsPremises)
                reject(null)
            })
        }
        const initializeValues = async (premises: Premises) => {
            setInitialValues({
                adress: premises.adress ? premises.adress : "",
                city: premises.city ? premises.city : "",
                premisesType: premises.premisesType ? premises.premisesType : PremisesType.OFFICE,
                size: premises.size ? premises.size : 0,
                description: premises.description ? premises.description : "",
                propertyOwner: premises.propertyOwner ? premises.propertyOwner : "",
                contactName: premises.contactName ? premises.contactName : "",
                contactEmail: premises.contactEmail ? premises.contactEmail : "",
                contactPhone: premises.contactPhone ? premises.contactPhone : "",
                images: premises.images,
                imageId: premises.imageId,
                note: premises.note ? premises.note : ""
            });
        }
        fetchPremisesById().then((p) => initializeValues(p))
    }, [premisesId])


    const onSubmitPremises = (values: FormikValues) => {
        setPremisesBody(values as Premises)
        setShowPreview(true)
    }


    const handleOnsubmit = async (premisesBody: Premises) => {
        await supabase.from('Premises').update([
            {
                adress: premisesBody.adress,
                city: premisesBody.city,
                premisesType: premisesBody.premisesType,
                size: premisesBody.size,
                description: premisesBody.description,
                propertyOwner: premisesBody.propertyOwner,
                contactName: premisesBody.contactName,
                contactPhone: premisesBody.contactPhone,
                contactEmail: premisesBody.contactEmail,
                note: premisesBody.note
            },
        ]).eq("id", originalPremises?.id)

        return new Promise<PremisesResponse>((resolve, reject) => {
            resolve({id: premisesId.id, success: !(premisesId.id === undefined)})
            reject(false)
        })
    }

    if (!isAuthed) return <p>Logga in för att redigera lokal</p>
    return (

        <div>

            {isAuthed && originalPremises && initialValues ?
                (
                    <Container>
                        <Formik
                            innerRef={formikRef}
                            initialValues={initialValues}
                            validationSchema={premisesSchema}
                            onSubmit={(values) => {
                                console.log(formikRef.current.values.images)
                                onSubmitPremises(values)
                            }}
                        >
                            <Form>
                                <Stack gap={3}>
                                    {/* ADRESS */}
                                    <label className="block" htmlFor="adress">
                                        Adress
                                    </label>
                                    <Field
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                        id="adress"
                                        placeholder="Adress"
                                        name="adress"
                                    />
                                    <ErrorMessage name="adress" component={ErrorText}/>

                                    {/* CITY */}
                                    <label className="block" htmlFor="city">
                                        Stad
                                    </label>
                                    <Field
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                        id="city"
                                        placeholder="Stad"
                                        name="city"
                                    />
                                    <ErrorMessage name="city" component={ErrorText}/>

                                    {/* LOKALTYP */}
                                    <label htmlFor="Email" className="block">
                                        Lokaltyp
                                    </label>
                                    <Field
                                        as="select"
                                        id="premisesType"
                                        placeholder="Lokaltyp"
                                        name="premisesType"
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                    >
                                        <option value="Kontor"> Kontor</option>
                                        <option value="Kombi"> Kombi</option>
                                        <option value="Lager"> Lager</option>
                                        <option value="Butik"> Butik</option>
                                    </Field>
                                    <ErrorMessage name="premisesType" component={ErrorText}/>

                                    {/* STORLEK */}
                                    <label htmlFor="Phone" className="block">
                                        Storlek
                                    </label>
                                    <Field
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                        id="size"
                                        placeholder="Storlek"
                                        name="size"
                                    />
                                    <ErrorMessage name="size" component={ErrorText}/>

                                    {/* description */}
                                    <div>
                                        <label htmlFor="description" className="block">
                                            Beskrivning
                                        </label>
                                        <Field
                                            className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                            as="textarea"
                                            id="description"
                                            placeholder="Beskrivning"
                                            name="description"
                                            style={{height: 200, width: "100%"}}
                                        />
                                        <ErrorMessage name="description" component={ErrorText}/>
                                    </div>
                                    {/* PropertyOwner */}
                                    <label htmlFor="Phone" className="block">
                                        Fastighetsägare
                                    </label>
                                    <Field
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                        id="propertyOwner"
                                        placeholder="Fastighetsägare"
                                        name="propertyOwner"
                                    />
                                    <ErrorMessage name="propertyOwner" component={ErrorText}/>

                                    {/* contactName */}
                                    <label htmlFor="Phone" className="block">
                                        Kontaktperson
                                        Namn
                                    </label>
                                    <Field
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                        id="contactName"
                                        placeholder="Namn"
                                        name="contactName"
                                    />
                                    <ErrorMessage name="contactName" component={ErrorText}/>

                                    {/* contactEmail */}
                                    <label htmlFor="Phone" className="block">
                                        Email
                                    </label>
                                    <Field
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                        id="contactEmail"
                                        placeholder="Email"
                                        name="contactEmail"
                                    />
                                    <ErrorMessage name="contactEmail" component={ErrorText}/>

                                    {/* contactPhone */}
                                    <label htmlFor="Phone" className="block">
                                        Telefonnummer
                                    </label>
                                    <Field
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                        id="contactPhone"
                                        placeholder="Telefonnummer"
                                        name="contactPhone"
                                    />
                                    <ErrorMessage name="contactPhone" component={ErrorText}/>
    
    
                                    {/* ADRESS */}
                                    <label className="block" htmlFor="note">
                                        Anteckning
                                    </label>
                                    <Field
                                        className="mb-6 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline block"
                                        id="note"
                                        placeholder="Anteckning"
                                        name="note"
                                    />
                                    <Button type="submit" style={{margin: 10}} label="Ändra Lokal"/>
                                </Stack>
                            </Form>
                        </Formik>


                        <Modal show={showPreview && premisesBody != null} onHide={() => setShowPreview(false)}
                               size={"xl"}>
                            <Modal.Header>
                                <Modal.Title>Så här ser den ut <p>OBS: Bilder syns ej, se till att alla bildnamn finns
                                    med</p></Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <CardContent adress={premisesBody!!.adress}
                                             city={premisesBody!!.city}
                                             premisesType={premisesBody!!.premisesType} size={premisesBody!!.size}
                                             description={premisesBody.description ? premisesBody.description : ""}
                                             contactPhone={premisesBody.contactPhone ? premisesBody.contactPhone : ""}
                                             contactEmail={premisesBody.contactEmail ? premisesBody.contactEmail : ""}
                                             contactName={premisesBody.contactName ? premisesBody.contactName : ""}
                                             images={premisesBody.images ? premisesBody.images : []}
                                             imageId={premisesId.id!!}
                                             note={premisesBody.note ? premisesBody.note : ""}
                                />

                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="p-button-secondary" onClick={() => setShowPreview(false)}>
                                    Avbryt
                                </Button>
                                <Button onClick={async () => {
                                    await handleOnsubmit(premisesBody).then((res: PremisesResponse) => {
                                        console.log("FRÅN PROMISE", res)
                                        if (res.success) {
                                            navigate(`/premises/${res.id}`, {relative: "route"})
                                        } else {
                                            window.alert("Något gick fel")
                                        }

                                    })
                                }} label={"Ladda upp lokal"}>
                                </Button>
                            </Modal.Footer>
                        </Modal>

                    </Container>
                )
                : (<p> Du måste logga in för att updatera lokaler</p>)
            }
        </div>
    )
}

