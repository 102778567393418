import React from 'react'
import {Col, Container, Row} from 'react-bootstrap'
import {useLoggedInEmail} from "../../hooks";
import {Link, useNavigate} from "react-router-dom";
import {Dropdown} from 'primereact/dropdown';

export const NavigationTopBar = () => {

    const loggedInEmail = useLoggedInEmail()
    const navLinkDropdownValues = [
        {label: "Ladda upp", value: "/upload"},
        {label: "Fastighetsägare", value: "/premises-owners"},
        {label: "Logga ut", value: "/logout"}
    ]

    const navigation = useNavigate()

    return (

        <Container>
            <Row>
                <Col md={6}>
                    <Link to={"/"}> <img src="/images/logo.png" alt="LoFast"/></Link>
                </Col>
                <Col>
                    <Link to={"/news"}><p style={{color: "grey", marginTop: 10}}>Nyheter</p></Link>
                </Col>
                <Col>
                    <Link to={"/about"}><p style={{color: "grey", marginTop: 10}}> Om LoFast</p></Link>
                </Col>
                <Col>
                    <Link to={"/contact"}><p style={{color: "grey", marginTop: 10}}> Kontakta oss</p></Link>
                </Col>
                <Col>
                    {loggedInEmail ?
                        (<Dropdown value="Test" options={navLinkDropdownValues} placeholder={loggedInEmail}
                                   onChange={(e) => {
                                       navigation(e.value)
                                   }
                                   }/>
                        ) :
                        <Link to={"/login"}><p style={{color: "grey", marginTop: 10}}> Logga in</p></Link>}
                </Col>


            </Row>
        </Container>

    );
}
