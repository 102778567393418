import React, {useEffect, useState} from "react";
import {Container, Modal} from "react-bootstrap";
import {Card} from "primereact/card";
import {useNavigate, useParams} from "react-router-dom";
import {CardContent} from "../components/PremisesContent/CardContent";
import {supabase} from "../supabase/db";
import {Button} from "primereact/button";
import {PREMISES_TABLE, SUPABASE_PREMISES_BUCKET} from "../constants/Urls";
import {useIsAuthed} from "../hooks";

export const PremisesScreen = () => {


    const premisesId = useParams()
    const [premises, setPremises] = useState<any>(null)

    const [deleteModal, setDeleteModal] = useState<boolean>(false)
    const navigate = useNavigate()

    const isAuthed = useIsAuthed()

    const onDelete = async () => {
        if (premises.images) {
            let imageDeleteArray = []
            for (const image of premises.images) {
                let imageSplit = image.split("/")
                let imageName = imageSplit[imageSplit.length - 1]
                imageDeleteArray.push(`${imageName}`)
            }
            const {data} = await supabase.storage.from(SUPABASE_PREMISES_BUCKET).remove(imageDeleteArray)
            console.log("DELETING", data)
        }
        await supabase.from(PREMISES_TABLE).delete().eq("id", premises.id).then(() => navigate("/", {relative: "route"}))
    }

    const onEdit = () => {
        navigate(`/edit/${premisesId.id}`)
    }

    useEffect(() => {
        const fetchPremisesById = async () => {
            const {data} = await supabase
                .from(PREMISES_TABLE)
                .select()
                .eq("imageId", premisesId.id)
                .single()
            setPremises(data)
        }
        fetchPremisesById()
    }, [premisesId])

    if (premises) {
        return (
            <Container>
                <Button icon="pi pi-arrow-left" className="p-button-rounded p-button-secondary p-button-outlined m-3" aria-label="Bookmark"  label="Tillbaka" onClick={() => navigate("/")}/>
                <Card>
                    <Container>
                        <CardContent
                            {...premises}
                        />
                    </Container>
                </Card>

                {isAuthed ? (
                    <div>
                        <Button className="p-button-secondary" label="Ändra" style={{margin: 10}} onClick={onEdit}/>
                        <Button className="p-button-warning" label="Ta bort" style={{margin: 10}}
                                onClick={() => setDeleteModal(true)}/>
                    </div>
                ) : <React.Fragment/>}

                <Modal show={deleteModal}>
                    <Modal.Header>
                        Ta bort lokal
                    </Modal.Header>
                    <Modal.Body>
                        Är du säker på att du vill ta bort lokalen?
                        Du blir skickat till lokallistan efter borttagning.
                    </Modal.Body>

                    <Modal.Footer>
                        <Button className="p-button-secondary" label="Avbryt" style={{margin: 10}}
                                onClick={() => setDeleteModal(false)}/>
                        <Button className="p-button-warning" label="Ja, Ta bort" style={{margin: 10}}
                                onClick={onDelete}/>
                    </Modal.Footer>
                </Modal>
            </Container>


        );
    } else {
        return <Container>Ingen lokal med detta ID finns</Container>;
    }
};
