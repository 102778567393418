import {supabase} from "../../supabase/db";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

export const Logout = () => {

    const navigate = useNavigate()
    useEffect(() => {
        supabase.auth.signOut().then(() => { navigate("/")})
    } )
    return <></>
}