import React, {useEffect, useState} from "react";
import {useIsAuthed} from "../hooks";
import Post from "../components/news/Post";
import {Container, Row, Stack} from "react-bootstrap";
import {supabase} from "../supabase/db";
import * as Yup from "yup";
import {News} from "../data/NewsData";
import {ErrorMessage, Field, Form, Formik, FormikValues} from "formik";
import {ErrorText} from "./User/ErrorText";
import {Card} from "primereact/card";
import {Button} from "primereact/button";


const NewsScreen = () => {

    const isAuthed = useIsAuthed()

    const [news, setNews] = useState<any>([])


    useEffect(() => {
        const fetchNews = async () => {
            const {data} = await supabase.from("news").select().order("created_at", {ascending: false})
            if (data) {
                setNews(data)
            }
        }
        fetchNews()
    }, [])


    const initalValues = {
        title: "",
        text: "",
        link:""
    }

    const submitForm = async (formikValues: FormikValues) => {
        console.log(formikValues)
        if (formikValues.title && formikValues.text) {
            await supabase.from("news").insert({
                title: formikValues.title,
                text: formikValues.text,
                link: formikValues.link
            }).then(() => window.location.reload())
        }
    }

    const deletePost = async (id: Number) => {
        await supabase.from("news").delete().eq("id", id).then(() => window.location.reload())
    }


    return (
        <Container>
            {news && news.length > 0 && news as News[]  ? (
                (news as News[]).map(post => {
                    return (
                        <Row>
                            <Post id={post.id} created_at={post.created_at} title={post.title} text={post.text} link={post.link}/>
                            {isAuthed ? ( <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-5"
                                                  aria-label="Trash" onClick={() => deletePost(post.id)}/>) : <React.Fragment/>}

                        </Row>
                    )
                })
            ) : <React.Fragment/>}
            {isAuthed ? (
                <React.Fragment>
                    <Card title="Lägg till nyhet"
                          subTitle="Detta syns endast som inloggad"
                          style={{position: "absolute", top: "10vh", right: 0, width: "30vw"}}>
                        <Formik initialValues={initalValues} validationSchema={postSchema} onSubmit={submitForm}>
                            <Form>
                                <Stack gap={3}>
                                    <label className="block"> Titel</label>
                                    <Field
                                        id="title"
                                        placeholder="Titel"
                                        name="title"
                                    />
                                    <ErrorMessage name="title" component={ErrorText}/>

                                    <label className="block"> Text</label>
                                    <Field
                                        id="text"
                                        placeholder="Text"
                                        name="text"
                                        as="textarea"
                                    />
                                    <ErrorMessage name="text" component={ErrorText}/>

                                    <label className="block"> Dela en länk (valfri)</label>
                                    <Field
                                        id="link"
                                        placeholder="Länk"
                                        name="link"
                                    />
                                    <ErrorMessage name="link" component={ErrorText}/>

                                    <Button type="submit" label="Lägg upp nyhet"/>
                                </Stack>
                            </Form>
                        </Formik>
                    </Card>
                </React.Fragment>
            ) : <React.Fragment/>}
        </Container>
    )
}

export default NewsScreen


const postSchema = Yup.object().shape({
    title: Yup.string().required("Måste ange titel"),
    text: Yup.string().required("Måste ange text")
})