import {News} from "../../data/NewsData";
import {Card} from "primereact/card";
import React from "react";

const Post = (post: News) => {

    let cardStyle = {
        width: "50vw",
        backgroundColor: "rgba(243,243,243,0.8)",
        marginTop: "3vh",
        "text-align": "center",
        color:"grey"
    }

    let formattedDate = () => {
        let spl = post.created_at.split("T")
        return spl[0]
    }

    return (
        <Card style={cardStyle} title={post.title} subTitle={formattedDate}>
            {post.text}
            <br/>
            {post.link ? <a href={post.link}>Länk till lokalen</a> : null}

        </Card>
    )
}

export default Post