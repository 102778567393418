import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {Image} from "primereact/image";
import {Divider} from "primereact/divider";

interface ContactDetails {
    imageUrl: String,
    email: String,
    phoneNumber: String
}

export const Contact = () => {
    
    const ContactDetailsComponent = ({imageUrl, email, phoneNumber}: ContactDetails) => {
        return (
            <Row>
                <Divider />
                <Col md={2}>
                    <Image src={`/images/${imageUrl}`} width="180" alt="Per"/>
                </Col>
                <Col>
                    <p style={{color: "grey"}}> Kontakt: </p>
                    <ul style={{color: "grey"}}>
                        <li> Email: <a href={`mailto:${email}`}> {email}</a></li>
                        <li> Telefon: <a href={`tel:${phoneNumber}`}>{phoneNumber}</a></li>
                    </ul>
                </Col>
                <Divider />
            </Row>
        );
    }
    
    return (
        <Container style={{marginTop: "5%"}}>
            <ContactDetailsComponent email="per@lofast.se" phoneNumber="0708972343" imageUrl="per.jpg"/>
            <ContactDetailsComponent email="anci@lofast.se" phoneNumber="0708311785" imageUrl="anci.jpg"/>
        </Container>
    )
}
