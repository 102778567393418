import React, {useEffect, useState} from 'react'
import {Container} from "react-bootstrap";
import {supabase} from "../supabase/db";
import {useIsAuthed} from "../hooks";


interface PropertyOwner {
    propertyOwner: String
}

export const PremisesOwners = () => {


    const isAuthed = useIsAuthed()
    const [uniquePropertyOwners, setUniquePropertyOwners] = useState<PropertyOwner[] | any>([])

    useEffect(() => {
        const fetchAllUniquePremisesOwners = async () => {
            const res = isAuthed ? await supabase
                    .from('unique_property_owners')
                    .select('*')
                    .order('propertyOwner', { ascending: true })
                : null
            if (res && res.data && res.data as PropertyOwner[]) {
                setUniquePropertyOwners(res.data as PropertyOwner[])
            }
        }
        fetchAllUniquePremisesOwners()
    }, [isAuthed])


    const OwnerItem = (owner: PropertyOwner) => {
        const listItemStyles = {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontFamily: 'sans-serif',
            fontSize: '16px',
            color: '#333',
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            padding: '16px',
            marginBottom: '16px',
            cursor: 'pointer',
        };

        return (
            <li style={listItemStyles}>{owner.propertyOwner}</li>
        )
    }

    return (
        <Container style={{marginTop: "5%"}}>
            {
                uniquePropertyOwners.map((owner: PropertyOwner, index: number) => {
                    return (
                        <OwnerItem key={index} propertyOwner={owner.propertyOwner}/>
                    )
                })
            }
        </Container>
    )
}
