import React from 'react'
import {PremisesList} from '../components/premiseslist/PremisesList';
import {Container} from "react-bootstrap";
import {useLatestPost} from "../hooks";
import Post from "../components/news/Post";
import WelcomeComponent from "../components/WelcomeComponent";
import {Button} from "primereact/button";

export const Home = () => {
    const latestNews = useLatestPost()


    return (
        <div>
            <WelcomeComponent/>
            {latestNews ? (
                <Container className="d-flex justify-content-center" style={{marginBottom: 10}}>
                    <Post id={latestNews.id} created_at={latestNews.created_at} title={latestNews.title}
                          text={latestNews.text} link={latestNews.link}/>
                </Container>
            ) : <React.Fragment/>}
            <PremisesList/>

            <Button style={{position: "fixed", right: 10, bottom: 10}} icon="pi pi-arrow-up"
                    className="p-button-rounded p-button-secondary p-button-outlined" onClick={() => window.scroll(0,0)}/>

        </div>
    );
}
