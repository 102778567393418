export interface Premises {
	id?: String;
	adress: String;
	city: String;
	premisesType: PremisesType;
	images?: Array<string>;
	size: Number;
	description?: String;
	propertyOwner?: String;
	contactName?: String;
	contactPhone?: String;
	contactEmail?: String;
	imageId: String,
	note: String
}



export enum PremisesType {
	OFFICE = "Kontor",
	WAREHOUSE = "Lager",
	STORE = "Butik",
	KOMBI = "Kombi",
}
