import {Premises} from "../data/Premises";
import {supabase} from "./db";
import {PUBLIC_PREMISESIMAGES_URL, SUPABASE_PREMISES_BUCKET} from "../constants/Urls";
import {MutableRefObject} from "react";

export async function handleImageUploadWithRenames(premisesBody: Premises, imagePathArray: string[], formikRef: MutableRefObject<any>) {
    for (const image of formikRef.current.values.images) {
        const {
            data,
            error
        } = await supabase.storage.from(SUPABASE_PREMISES_BUCKET).upload(`/${image.name}`, image);
        let path = data ? data.path : "NO PATH"
        imagePathArray.push(PUBLIC_PREMISESIMAGES_URL + path)
        if (error) {
            console.log("Somthing went wrong with uploading")
        }
    }
}