import React from 'react';
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {Container} from "react-bootstrap"
import {NavigationTopBar} from './components/navigationbar/NavigationTopBar';
import {Routes, Route} from "react-router-dom";
import {Home} from './screens/Home';
import {PremisesScreen} from './screens/PremisesScreen';
import {About} from './screens/About';
import {Contact} from './screens/Contact';
import {Login} from './screens/User/Login';
import {Upload} from "./screens/Upload";
import {EditPremises} from "./screens/EditPremises";
import {background} from "./constants/styles";
import {ProtectedRoutes} from "./ProtectedRoutes";
import {Logout} from "./screens/User/Logout";
import NewsScreen from "./screens/NewsScreen";
import {PremisesOwners} from "./screens/PremisesOwners";

const App: React.FC = () => {

    return (
        <Container fluid className="App" style={background}>
            <NavigationTopBar/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route element={<ProtectedRoutes/>}>
                    <Route path="/upload" element={<Upload/>}/>
                    <Route path="/edit/:id" element={<EditPremises/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="/premises-owners" element={<PremisesOwners/>}/>
                </Route>
                <Route path="/premises/:id" element={<PremisesScreen/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/news" element={<NewsScreen/>}/>
            </Routes>
        </Container>
    );
}

export default App;
