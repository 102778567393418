import React from 'react'
import {Container, Row} from "react-bootstrap";
import {Card} from "primereact/card";

export const About = () => {


    return (
        <Container className="text-center">

            <Card className="m-4" title="Om LoFast" subTitle="Söker ert företag nya lokaler?">
                Hos LoFast finns ett stort utbud av lediga lokaler och LoFast hjälper er hitta lokaler som passar
                för
                just ert företags specifika behov. Här kan ni söka bland kontor, lagerlokaler, butikslokaler och en
                mängd andra lediga lokaler i Västra Sverige. Behöver du hjälp med uthyrningsfrågor eller transaktionsfrågor så kontakta oss.
            </Card>

            <Card className="m-4" title="30 års erfarenhet i branschen">
                <Row className="justify-content-md-center m-2" md={2}>
                    <p>Lofast har 30 års erfarenhet i branschen och en mycket god kunskap och inblick om alla
                        frågor gällande fastigheter och lokaler. Lofast har med sin beprövade metod hjälpt hundratals
                        företag med deras lokalflytt. Lofast arbetar med nästan alla fastighetsägare i Göteborg stora
                        som
                        små. Bostadrättsföreningar, företag som vill flytta innan hyreskontraktet gått ut, är idag också
                        en
                        viktig målgrupp för Lofast.
                    </p>
                </Row>
            </Card>

            <Card className="m-4" title="Behöver du eller ditt företag en en hemsida?">
                <Row className="justify-content-md-center m-2" md={2}>
                    <p>
                        Denna hemsida är gjord av Axel Hertzberg. Behöver du en hemsida? Kontakta <br/> <a href="mailto:axelhertzberg@outlook.com">axelhertzberg@outlook.com</a>.
                    </p>
                </Row>
            </Card>


        </Container>
    )
}

//
// 20 års erfarenhet i branschen
//
// Lofast med sina 20 års erfarenhet i branschen har idag en mycket god kunskap och inblick om alla frågor gällande fastigheter och lokaler. Lofast har med sin beprövade metod hjälpt hundratals företag med deras lokalflytt. Lofast arbetar med nästan alla fastighetsägare i Göteborg stora som små. Bostadrättsföreningar, företag som vill flytta innan hyreskontraktet gått ut, är idag också en viktig målgrupp för Lofast.
